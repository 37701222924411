@keyframes move1 {
  0% {
    transform: translate3d(0px, -100%, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes move2 {
  0% {
    transform: translate3d(0px, -100%, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

@keyframes in {
  0% {
    transform: translate3d(0px, -100%, 0px);
  }
  100% {
    transform: translate3d(0px, 0px, 0px);
  }
}

.animated-row:nth-child(2n + 1) {
  animation: move1 0.5s forwards;
}

.animated-row:nth-child(2n) {
  animation: move2 0.5s forwards;
}

.animated-row:first-child {
  animation: in 0.5s forwards;
}
